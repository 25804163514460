import * as React from 'react';
import {alpha, styled} from '@mui/material/styles';
import logo_large from "./fieldreport_large.png";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AdbIcon from '@mui/icons-material/Adb';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

import "../Geocoder/geocoder.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import {
    AppBar,
    Avatar,
    Box,
    Button,
    IconButton,
    InputBase,
    Menu,
    MenuItem, ToggleButton, ToggleButtonGroup,
    Toolbar,
    Tooltip, Typography
} from "@mui/material";
import {useEffect} from "react";
import {Layers, Whatshot, Workspaces} from "@mui/icons-material";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const pages = [];
const settings = [];

export default function SearchAppBar(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [geocoder, setGeocoder] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        const {mapRef} = props;

        if (mapRef && mapRef.current && !geocoder) {
            let mapboxMap = mapRef.current.getMap();
            let mapboxGeocoder = new MapboxGeocoder({
                accessToken: props.mapboxAccessToken,
                mapboxgl: mapboxMap,
                countries: 'ua,pl,ru,md,by'
            });
            //mapboxGeocoder.addTo('#geosearch');
            //mapboxGeocoder.onAdd(mapboxMap);
            mapboxMap.addControl(mapboxGeocoder);
            setGeocoder(mapboxGeocoder);
        }
    })

    const {layerSettings, handleLayerSettings} = props;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0, padding: '2px', height: '70px', display: { xs: 'none', md: 'flex' }}}>
                        <img src={logo_large} style={{ height: '100%'}} alt={"fieldreport.org Logo"} />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0, mr: 1 }}>
                        <ToggleButtonGroup
                            color="secondary"
                            value={layerSettings}
                            onChange={handleLayerSettings}
                            sx={{ display: 'flex', flexGrow: 0 }}
                        >
                            <ToggleButton value={"clusters"}>
                                <Tooltip title="Toggle cluster layer">
                                    <Workspaces />
                                </Tooltip>
                            </ToggleButton>

                            <ToggleButton value={"heatmap"}>
                                <Tooltip title={"Toggle heatmap layer"}>
                                    <Whatshot />
                                </Tooltip>
                            </ToggleButton>

                            <ToggleButton value={"frontline"}>
                                <Tooltip title={"Toggle frontline layer"}>
                                    <Layers />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}