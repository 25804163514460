import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            light: 'rgb(38,50,68)',
            main: 'rgb(31 41 55)',
            dark: 'rgb(26,33,45)',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

export default theme;