import * as React from 'react';
import {useState, useEffect, useMemo, useRef} from 'react';
import ReactMapGL, {Layer, Source, useControl} from 'react-map-gl';
import * as turf from "@turf/turf";
//import Geocoder from "./Geocoder/Geocoder";
import SearchAppBar from "./AppBar/SearchAppBar";
import {
    clusterLayer,
    clusterCountLayer,
    unclusteredPointLayer,
    heatmapLayer,
    ukrBoundaryLayer,
    ukrFrontlinesLayer
} from '../layers';
import ControlPanel from "../control-panel";
import InfoPanel from "../info-panel";
import "mapbox-gl/dist/mapbox-gl.css";
import {GeolocateControl} from "mapbox-gl";
import {Box, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {Layers, Whatshot, Workspaces} from "@mui/icons-material";

const MAPBOX_TOKEN="pk.eyJ1IjoibWZlbGRoZWltIiwiYSI6ImNrd2RtbHR0bTBtMGkydHQzYXFkd3gwenoifQ.wSNDW364G7Nk9fDAxzSLKA";
//const MAP_STYLE = "mapbox://styles/mapbox/streets-v11"
//const MAP_STYLE = "mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y"
const MAP_STYLE = "mapbox://styles/mapbox/satellite-streets-v11"
//const MAP_STYLE="mapbox://styles/mapbox/dark-v10"
//const MAP_STYLE="mapbox://styles/mapbox/cjerxnqt3cgvp2rmyuxbeqme7"


export default function Map() {
    const mapRef = useRef(null);

    const [allDays, useAllDays] = useState(true);
    const [showCluster, useShowCluster] = useState(false);
    const [timeRange, setTimeRange] = useState([0, 0]);
    const [selectedTime, selectTime] = useState(0);
    const [featureCollection, setFeatureCollection] = useState(null);
    const [frontlineData, setFrontlineData] = useState(null);
    const [areaStats, setAreaStats] = useState({
        occupied: 0,
        occupied_before: 0,
        contested_ukr: 0,
        contested_rus: 0,
        liberated: 0
    });

    const [layerSettings, setLayerSettings] = useState(() => ['frontline', 'heatmap']);

    useEffect(() => {
        fetch('https://exyl276vr3.execute-api.eu-west-1.amazonaws.com/test/deepstate')
            .then(resp => resp.json())
            .then(json => {
                let outputStats = {
                    occupied: null,
                    occupied_before: 0,
                    contested_ukr: null,
                    contested_rus: null,
                    liberated: null
                };
                let localAreaStats = {
                    occupied: null,
                    occupied_before: 0,
                    contested_ukr: null,
                    contested_rus: null,
                    liberated: null
                };

                setFrontlineData({
                    type: "FeatureCollection",
                    features: json.map.features.filter(feature => {
                        if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'GeometryCollection') {
                            let type = determineDeepstateAreaType(feature.properties)
                            if (type) {
                                if (feature.geometry.type === 'GeometryCollection') {
                                    feature = turf.union(...feature.geometry.geometries);
                                }
                                localAreaStats[type] = localAreaStats[type] ?
                                    turf.union(localAreaStats[type], feature.geometry) : feature.geometry;

                            }
                            return true;
                        }
                        return false;
                    })
                });

                for (const [key, value] of Object.entries(localAreaStats)) {
                    try {
                        outputStats[key] = turf.area(value) / 1000000;
                    } catch (e) {
                    }
                }
                setAreaStats(outputStats);
            });
        fetch('https://exyl276vr3.execute-api.eu-west-1.amazonaws.com/test/firms/VIIRS_NOAA20_NRT/UKR/2')
            .then(resp => resp.text())
            .then(csv => csv.split(/\n/))
            .then(csvLines => {
                csvLines.shift();
                let features = [];
                csvLines.forEach(csvLine => {
                    features.push(mapFeature(csvLine.split(/,/)));
                });

                features = features.sort((a, b) => a.properties.time - b.properties.time).reverse();

                const endTime = features[0].properties.time;
                const startTime = features[features.length - 1].properties.time;
                const json = {
                    "type": "FeatureCollection",
                    "features": features
                };

                setTimeRange([startTime, endTime]);
                setFeatureCollection(json);
                selectTime(endTime);
            });

    }, []);

    const firmsData = useMemo(() => {
        return allDays ? featureCollection : filterFeaturesByDay(featureCollection, selectedTime);
    }, [featureCollection, allDays, selectedTime]);

    const onClick = event => {
        const mapboxSource = mapRef.current.getSource('firmsdata');
        const feature = event.features[0];
        const clusterId = feature.properties.cluster_id;

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
                return;
            }

            mapRef.current.easeTo({
                center: feature.geometry.coordinates,
                zoom,
                duration: 500
            });
        });
    };

    /*    const DistanceControl = (props) => {
            useControl(() => new GeolocateControl(props), {
                position: props.position
            });

            return null;
        }*/

    const filterFeaturesByDay = (featureCollection, time) => {
        const date = new Date(time);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const features = featureCollection.features.filter(feature => {
            const featureDate = new Date(feature.properties.time);
            return (
                featureDate.getFullYear() === year &&
                featureDate.getMonth() === month &&
                featureDate.getDate() === day
            );
        });
        return {
            type: 'FeatureCollection',
            features: features
        };
    }

    const mapFeature = (data) => {
        return {
            "type": "Feature",
            "properties": {
                //"id": "ak16994521",
                "mag": 2.3,
                "time": Date.parse(data[6] + 'T' + data[7].padStart(4, '0').match(/.{2}/g).join(":") + ":00+00:00"),
                "felt": null,
                "tsunami": 0
            },
            "geometry": {
                "type": "Point",
                "coordinates": [
                    parseFloat(data[2]),
                    parseFloat(data[1]),
                    0
                ]
            }
        }
    }

    const determineDeepstateAreaType = (properties) => {
        let type = null;
        switch (properties.fill) {
            case '#0288d1':
            case '#0f9d58':
                type = 'liberated';
                break;
            case '#a52714':
                type = 'occupied_before'
                break;
            case '#880e4f':
                type = 'occupied';
                break;
            case '#bcaaa4':
                type = 'contested_rus';
                break;
            case '#bdbdbd':
                type = 'contested_ukr';
                break;
            default:
        }
        return type;
    }

    const handleLayerSettings = (
        event,
        layerSettings
    ) => {
        setLayerSettings(layerSettings);
    };
    return (
        <>

            <SearchAppBar
                mapRef={mapRef}
                mapboxAccessToken={MAPBOX_TOKEN}
                handleLayerSettings={handleLayerSettings}
                layerSettings={layerSettings}
            />

            <ReactMapGL
                ref={mapRef}
                mapStyle={MAP_STYLE}
                style={{width: "auto", height: "calc(100vh - 75px)"}}
                interactiveLayerIds={[clusterLayer.id]}
                onClick={onClick}
                mapboxAccessToken={MAPBOX_TOKEN}
                initialViewState={{
                    longitude: 31.1656,
                    latitude: 48.3794,
                    zoom: 6,
                    //pitch: 60, // pitch in degrees
                    pitch: 89,
                    bearing: 0, // bearing in degrees
                }}
            >
                <Source type={"geojson"}
                        data={"https://raw.githubusercontent.com/wmgeolab/geoBoundaries/729d59ed25f7bcc7664fc6ca58755b4e8567e80c/releaseData/gbOpen/UKR/ADM0/geoBoundaries-UKR-ADM0_simplified.geojson"}>
                    <Layer {...ukrBoundaryLayer(MAP_STYLE)} />
                </Source>

                {layerSettings && layerSettings.includes('frontline') ?
                    <Source
                        type={"geojson"}
                        data={frontlineData}
                    >
                        <Layer {...ukrFrontlinesLayer} />
                    </Source> : <></>
                }

                {layerSettings && layerSettings.includes('clusters') ?
                    <Source
                        id="firmsdata"
                        type="geojson"
                        data={firmsData}
                        cluster={true}
                        clusterMaxZoom={10}
                        clusterRadius={50}
                    >
                        <Layer {...clusterLayer} />
                        <Layer {...clusterCountLayer} />
                        <Layer {...unclusteredPointLayer} />
                    </Source> : <></>
                }

                {layerSettings && layerSettings.includes('heatmap') ?
                    <Source
                        type="geojson"
                        data={firmsData}
                    >
                        <Layer {...heatmapLayer} />
                    </Source> : <></>
                }

                {/* <Source
                    id={"distancetool"}
                    type={"geojson"}
                    data={{
                        "type": "FeatureCollection",
                        "features": [{
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [31.1656, 48.3794]
                            }
                        }]
                    }}>
                    <DistanceControl
                        position="bottom-right"
                        displayControlsDefault={false}
                    />
                    <Layer type={"circle"}
                           paint={{
                               "circle-radius": 30,
                               "circle-color": "rgba(255,255,255,0)",
                               "circle-stroke-color": "rgba(0,0,0,255)",
                               "circle-stroke-width": 2
                           }}
                            />
                    />
                </Source>*/}


                {/* {
                    mapRef && mapRef.current ?
                        <Geocoder
                            mapRef={mapRef}
                            onViewportChange={viewport =>
                                mapRef.current?.flyTo({
                                    center: [viewport.longitude, viewport.latitude],
                                    zoom: viewport.zoom
                                })
                            }
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                        /> : <></>
                }*/}
            </ReactMapGL>

            {/* <div className={"side-panel"}>
                <ControlPanel
                    startTime={timeRange[0]}
                    endTime={timeRange[1]}
                    selectedTime={selectedTime}
                    allDays={allDays}
                    showCluster={showCluster}
                    onChangeShowCluster={useShowCluster}
                    onChangeTime={selectTime}
                    onChangeAllDays={useAllDays}
                />

                <InfoPanel
                    featureCollection={firmsData}
                    areaStats={areaStats}
                />
            </div>*/}
        </>
    );
}