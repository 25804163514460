export function ukrBoundaryLayer(mapType) {
    return {
        id: 'boundary',
        type: 'line',
        paint: {
            'line-opacity': 0.5,
            'line-width': 8,
            'line-color': '#fff'//mapType.match(/satellite/) ? '#fff' : '#000'
        }
    }
}

export const ukrFrontlinesLayer = {
    id: 'frontlines',
    type: 'fill',
    paint: {
        'fill-color' : [
            'interpolate',
            ['exponential', 0.5],
            ['zoom'],
            10,
            ['get', 'fill'],
            12,
            'rgba(255,255,255,0)'
        ],
        'fill-outline-color': ['get', 'stroke'],
        'fill-opacity': [
            'interpolate',
            ['exponential', 0.3],
            ['zoom'],
            10,
            0.5,
            12,
            1
        ]
    }
};

export const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'firmsdata',
    filter: ['has', 'point_count'],
    paint: {
        'circle-blur': 0.5,
        'circle-opacity': 0.6,
        'circle-color': ['step', ['get', 'point_count'], '#ffea00', 100, '#ff9b00', 750, '#ff0000'],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
};

export const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'firmsdata',
    filter: ['has', 'point_count'],
    layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
    }
};

export const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'firmsdata',
    filter: ['!', ['has', 'point_count']],
    paint: {
        'circle-opacity': 0.6,
        'circle-blur': 0.5,
        'circle-color': '#ff0000',
        'circle-radius': 7,
    }
};

const MAX_ZOOM_LEVEL = 12;

export const heatmapLayer = {
    id: 'heatmap',
    maxzoom: MAX_ZOOM_LEVEL,
    type: 'heatmap',
    paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(255,255,255,0)',
            0.2,
            'rgba(255,255,102,125)',
            0.4,
            'rgba(255,204,0,125)',
            0.6,
            'rgba(255,153,0,125)',
            0.8,
            'rgba(243,118,81,125)',
            0.9,
            'rgba(255,51,60,255)'
        ],
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
        // Transition from heatmap to circle layer by zoom level
        'heatmap-opacity': 0.6
    }
};
