import * as React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Map from "./Components/Map";
import {ThemeProvider} from "@mui/material";
import theme from './theme';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path={"/"} element={<Map />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
